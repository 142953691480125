#galleryTitle {
    padding-bottom: 2rem;
}

#instructions {
    padding-bottom: 2rem;
    text-align: left;
    display: inline-block;
}

#container {
    margin-top: auto;
    text-align: center;
}

#dropdownToggle {
  color: black;  
  background: white;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  font-size: 22px; 
  text-align: left;
  border-radius: .4rem;
}

#dropdownToggle:hover {
  filter: brightness(70%);
  border: none;
}

#backgroundForSelect {
    background: #1E1C1B;
    height: 4.5rem;
    border-radius: .4rem;
    align-content: center;
}

#selectRow {
    text-align: center;
    justify-content: center;
}

#imagesRow {
    text-align: center;
    justify-content: center; 
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    place-content: center;
    padding-top: 2rem;
}

.gallery img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin: 15px;
    border-radius: .4rem;
    transition: transform 0.3s ease;
}

.gallery img:hover {
    transform: scale(1.1); 
}

.carousel-item img {
    object-fit: cover;
    width: 100%;          
    height: 100vh;

}
  
  @media (max-width: 375px) {
    .carousel-item img {
      height: 40vh;
    }
  }
  
  @media (min-width: 1200px) {
    .carousel-item img {
      height: 80vh;
    }
  }
  
  @media (min-width: 1600px) {
    .carousel-item img {
      height: 70vh;
    }
  }

  @media (max-width: 1024px) {
    .carousel-item img {
      height: 75vh;
    }
  }
  
  @media (max-width: 768px) {
    .carousel-item img {
      height: 60vh;
    }
  }
  
  @media (max-width: 480px) {
    .carousel-item img {
      height: 50vh;
    }
  }

  #customizeModal .modal-content {
    background-color: #1E1C1B;
  }

  #closeGalleryButton {
    background: rgb(188, 156, 88);
    outline: black;
    border: black;
    color: black;
  }

  #closeGalleryButton:hover {
    filter: brightness(70%);
  }